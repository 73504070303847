import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import MDXComponents from 'src/components/mdx-components';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';

export default function Drawer({ heading, content, ctas, customData }) {
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <Container>
          <Toggle>Expand / Collapse Content</Toggle>
          <Content>
            <h2>{heading}</h2>
            {content}
            {!!ctas.length && (
              <InlineList centered>
                {ctas.map((cta, i) => {
                  const ctaFlags = Object.assign({ secondary: i > 0 }, JSON.parse(cta.customData));

                  return (
                    <li key={i}>
                      <Button to={cta.url} {...ctaFlags}>
                        {cta.text}
                      </Button>
                    </li>
                  );
                })}
              </InlineList>
            )}
          </Content>
        </Container>
      </Section>
    </MDXProvider>
  );
}

const Container = styled.details`
  padding: 0;
  margin: 0;
`;

const Toggle = styled.summary`
  text-align: center;
  list-style: none;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Content = styled.div`
  margin-top: 2rem;
`;
